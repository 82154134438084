@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

/* For WebKit browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 1px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background-color: #1a1a1a; /* Background color of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background-color: #ffffff; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Optional: Rounds the corners of the scrollbar thumb */
}

/* For Firefox */
html {
  scrollbar-width: thin; /* Makes scrollbar thin */
  scrollbar-color: #ffffff #1a1a1a; /* thumb color, track color */
}

body {
  background-color: #1a1a1a;
  margin: 0;
  padding: 0;

  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-style: normal;

  color: #fff;
}
p {
  margin: 0;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.roboto-thin {
  font-family: 'Roboto', sans-serif;
  font-weight: 100;
  font-style: normal;
}

.roboto-light {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-style: normal;
}

.roboto-regular {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-style: normal;
}

.roboto-medium {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-style: normal;
}

.roboto-bold {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-style: normal;
}

.roboto-black {
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
  font-style: normal;
}

.roboto-thin-italic {
  font-family: 'Roboto', sans-serif;
  font-weight: 100;
  font-style: italic;
}

.roboto-light-italic {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-style: italic;
}

.roboto-regular-italic {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-style: italic;
}

.roboto-medium-italic {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-style: italic;
}

.roboto-bold-italic {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-style: italic;
}

.roboto-black-italic {
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
  font-style: italic;
}

.bg-dark-subtle-my {
  background-color: rgba(42, 42, 42, 0.8);
  backdrop-filter: blur(5px);
  transition: all 0.3s ease-in-out;
}

/* Nav.jsx */

@media screen and (min-width: 768px) {
  .nav-bar-my {
    top: 0;
    scale: 1;
    transition: all 0.8s ease-in-out;
  }

  .nav-bar-my-hide {
    top: -100px;
    scale: 0.5;
    transition: all 0.2s ease-in-out;
  }

  .nav-bar-my:hover {
    animation: none;
    transform: scale(1.05);
    transition: transform 0.2s ease-in-out;
  }

  .margin-top-10-my {
    margin-top: 10px;
    transition: all 0.2s ease-in-out;
  }
}

.nav-Items-my {
  cursor: pointer;
  /* transition: all 0.2s ease-in-out; */
}
.nav-text-my {
  font-size: 12px;
}

.nav-Items-my:hover {
  color: #dc2545;
  animation: gelatine 0.3s;
}

/* Home.jsx */

.red-lines-profile {
  transform: translateY(430%);
}

.header-text-default {
  font-size: 2rem;
}

.header-text {
  font-size: 5.8rem;
  transition: all 0.2s ease-in-out;
}

.profile-card-wrapper {
  position: fixed;
}

.profile-card-wrapper {
  /* width: 20vw; */
  /* background-color: pink; */
  transition: all 0.2s ease-in-out;
  position: fixed;
}

.home-card-my:hover {
  transform: rotate(1deg) scale(1.01);
  transition: all 0.4s ease-in-out;
}

@media (max-width: 1550px) {
  .profile-card-wrapper {
    width: 24vw;
  }
}

@media (max-width: 1450px) {
  .profile-card-wrapper {
    width: 27vw;
  }
  /* .red-lines-profile{
    transform: translateY(400%);
  } */
}

@media (max-width: 1300px) {
  .profile-card-wrapper {
    width: 28vw; /* Adjusted to a more visible width */
  }
}

@media (max-width: 1050px) {
  .header-text {
    font-size: 5rem;
  }
}

@media (max-width: 992px) {
  body {
    padding: 15px;
  }
  .red-lines-profile {
    transform: translateY(380%);
  }
  .profile-card-wrapper {
    position: relative;
    width: 100%;
  }
}
@media (max-width: 810px) {
  .header-text {
    font-size: 4.5rem;
  }
}
@media (max-width: 768px) {
  .header-text {
    font-size: 4.5rem;
  }
  .nav-bar-my {
    /* top: auto; */
    bottom: 50px;
  }

  .bg-dark-subtle-mobile-my {
    background-color: rgba(42, 42, 42, 0.8);
    backdrop-filter: blur(5px);
    transition: all 0.3s ease-in-out;
  }
  .nav-text-my {
    display: none;
  }
}
@media (max-width: 500px) {
  .header-text {
    font-size: 3.5rem;
  }
}

.stats-text-default {
  font-size: 0.2rem;
}
.stats-text {
  font-size: 4.5rem;
  /* transition: all 0.2s linear; */
}

/* skills.jsx */
.skill-item {
  opacity: 0;
  transform: translateX(-100%);
  animation: slideIn 0.5s forwards; /* Animation lasts 0.5s */
}

/* Experience.jsx */

.exprience-card-my:hover {
  background-color: #4643432f;
  transform: scale(1.01);
  transition: all 0.4s ease-in-out;
}

.experience-status-my {
  text-align: end;
  margin-top: 10px;
  padding-right: 5px;
  transition: all 0.2s ease-in-out;
}

.exprience-card-my:hover .experience-status-my {
  margin: 0px;
  padding: 0px;
  transition: all 0.4s ease-in-out;
}

/* animations */

.gelatine {
  animation: gelatine 0.5s;
}
@keyframes gelatine {
  from,
  to {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(0.9, 1.1);
  }
  50% {
    transform: scale(1.1, 0.9);
  }
  75% {
    transform: scale(0.95, 1.05);
  }
}

.rotate-in-up-left {
  animation: rotate-in-up-left 2s ease;
}
@keyframes rotate-in-up-left {
  0% {
    transform-origin: left bottom;
    transform: rotate(90deg);
    opacity: 0;
  }
  100% {
    transform-origin: left bottom;
    transform: rotate(0);
    opacity: 1;
  }
}

.rotate-in-down-left {
  animation: rotate-in-down-left 2s ease;
}
@keyframes rotate-in-down-left {
  0% {
    transform-origin: left bottom;
    transform: rotate(-90deg);
    opacity: 0;
  }
  100% {
    transform-origin: left bottom;
    transform: rotate(0);
    opacity: 1;
  }
}

.bounce-in-right {
  animation: bounce-in-right 2s ease;
}
@keyframes bounce-in-right {
  0% {
    opacity: 0;
    transform: translateX(2000px);
  }
  60% {
    opacity: 1;
    transform: translateX(-30px);
  }
  80% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}

.bounce-in-left {
  animation: bounce-in-left 2s ease;
}

@keyframes bounce-in-left {
  0% {
    opacity: 0;
    transform: translateX(-2000px);
  }
  60% {
    opacity: 1;
    transform: translateX(30px);
  }
  80% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0);
  }
}

.hithere {
  animation: hithere 1s ease infinite;
}
@keyframes hithere {
  30% {
    transform: scale(1.2);
  }
  40%,
  60% {
    transform: rotate(-20deg) scale(1.2);
  }
  50% {
    transform: rotate(20deg) scale(1.2);
  }
  70% {
    transform: rotate(0deg) scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.opacity-0-my {
  opacity: 0;
}

.slideup {
  animation: slideup 1s;
}

@keyframes slideup {
  0% {
    transform: translateY(50%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
